import React from 'react'
import styled from 'styled-components'

import ImgBannerBg from '../images/img-banner-bg.jpg'
import ImgBannerTitle from '../images/img-banner-title.png'

const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const BannerBlurBG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #727071;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${ImgBannerBg});
  filter: blur(12px);
  z-index: 1;
`

const BannerBG = styled.img`
  max-width: 1440px;
  height: auto;
  z-index: 2;

  @media only screen and (max-width: 1440px) {
    max-width: 1280px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`

const BannerInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
`

const BannerTitle = styled.img`
  width: 800px;
  height: auto;

  @media only screen and (max-width: 767px) {
    width: 360px;
  }
`

const BannerButton = styled.a`
  padding: 1rem 3rem;
  color: #FFF;
  border-radius: .2rem;
  background-color: #F39801;
  font-size: 2.4rem;
  text-decoration: none;
  box-shadow: .6rem .6rem .2rem .1rem rgba(243, 152, 1, .4);
  transition: all .3s;
  outline: none;

  &:active,
  &:focus {
    color: #FFF;
    background-color: #F39801;
  }

  &:hover {
    color: #F39801;
    background-color: #FFF;
  }

  @media only screen and (max-width: 767px) {
    padding: .8rem 2.4rem;
    font-size: 1.4rem;
  }
`

const Banner = () => (
  <BannerContainer className="section banner">
    <BannerBlurBG />

    <BannerBG
      src={ImgBannerBg}
      alt="中国技术力量，ECUG Con 2022 全球技术大会"
      title="中国技术力量，ECUG Con 2022 全球技术大会"
    />

    <BannerInner>
      <BannerTitle
        src={ImgBannerTitle}
        alt="中国技术力量，ECUG Con 2022 全球技术大会"
        title="中国技术力量，ECUG Con 2022 全球技术大会"
      />

      <BannerButton
        href="http://live-market.qiniu.com/watch/10786937"
        target="_blank"
        rel="noopener nofollow"
        title="预约直播 - 中国技术力量 | ECUG Con 2022 全球技术大会"
      >
        预约直播
      </BannerButton>
    </BannerInner>
  </BannerContainer>
)

export default Banner
