import React from 'react'
import styled from 'styled-components'

const ECUGInfoContainer = styled.div`
  padding: 6rem 0 0;

  @media only screen and (max-width: 767px) {
    padding-bottom: 0;
  }
`

const Info = styled.div`
  width: 80%;
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  & > div {
    margin-bottom: 4rem;
    width: 40%;
    flex: 0 1 auto;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;

    & > div {
      width: auto;
    }
  }
`

const InfoTitle = styled.h2`
  margin: 0;
  margin-bottom: 2rem;
  color: #FFF;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.8rem;
`

const InfoDesc = styled.div`
  color: #FFF;
  font-size: 1.6rem;
  line-height: 3.2rem;
`

const ECUGInfo = () => (
  <ECUGInfoContainer className="section ecug-info">
    <Info>
      <div>
        <InfoTitle>
          ECUG
        </InfoTitle>

        <InfoDesc>
          全称为 Effective Cloud User Group（实效云计算用户组），成立于 2007 年的 CN Erlounge II，由许式伟发起，是科技领域不可或缺的高端前沿团体。作为行业技术进步的一扇窗口，ECUG 汇聚众多技术人，关注当下热点技术与尖端实践，共同引领行业技术的变革。
        </InfoDesc>
      </div>

      <div>
        <InfoTitle>
          ECUG Con
        </InfoTitle>

        <InfoDesc>
          是由 ECUG 社区主办的全球顶级技术盛会，至今已成功举办 14 届。期间，来自 Google、微软、BAT、华为、京东、携程、七牛云、PingCAP、饿了么等知名公司的权威技术大神纷纷参与，影响了数万名技术从业者。
        </InfoDesc>
      </div>
    </Info>
  </ECUGInfoContainer>
)

export default ECUGInfo
